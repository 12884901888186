import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1
      }}
    >
      <div>
        <Title>Site not found</Title>
        <Subtitle>You just hit a route that doesn&#39;t exist...</Subtitle>
        <InfoText>There's more content to be found in the mobile application!</InfoText>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;

const Title = styled.h1`
  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  color: #161616;
  margin-bottom: 24px;
`;

const Subtitle = styled.h2`
  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #161616;
`;

const InfoText = styled.h3`
  font-family: Karla;
  font-style: normal;
  font-size: 18px;
  line-height: 150%;
  color: #494949;
`;
